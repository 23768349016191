import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addOrderRow, createOrder, sendAlertEmail } from "../../../services";
import { toUSD } from "../../Common/Functions/toUSD";
import withLocalContext from "../../store/withLocalContext";
import { handlePaymentMethodSubmission } from "./PersonalInfo/Payment/CardFunctions";
import { usePersonInfo } from "./PersonalInfo/Provider";
import { ROUTES } from "../../../constants/routes";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import LoadingState from "../../Common/Components/LoadingState";
import {
  loadCartFromStorage,
  setCartConfig,
  setConfirmation,
  setGiftCard,
  setOrderDiscount,
  setOrderInstructions,
  setOrderMessage,
  setPopupHTML,
} from "../../store/actions";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import dayjs from "dayjs";
import ClearCart from "../../Popup/ClearCart";
import { sendOrderConfirmationEmails } from "../../Common/Functions/EmailHTML/OrderConfirm";
import {
  JSDateToExcelDate,
  timeDateToExcel,
} from "../../Common/Functions/jsDateToExcel";
import {
  createErrorHTML,
  largeOrderHTML,
} from "../../Common/Functions/createEmailHTML";
import OrderErrorMessage from "../../Popup/OrderErrorMessage";

const SubmitBtn = ({ total, context: { state: globalState, dispatch } }) => {
  const { state: localState = "" } = usePersonInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const lap = useScreenWidth("isLaptop");
  const [isProcessing, setIsProcessing] = useState(false);
  const isProcessingRef = useRef(isProcessing);
  const [errMsg, setErrMsg] = useState("");
  const initialOrderMsg = "Collecting order details...";
  const [orderMsg, setOrderMsg] = useState(initialOrderMsg);
  const timeOut = useRef();
  const inputErrText = "Uh oh! I found errors in some of the inputs above!";
  const orderErrText =
    "I'm sorry something went wrong with placing your order. Please refresh the page and try again.";
  let paymentErrText =
    "Oh no! A payment error occurred. Please refresh your page and try again.";

  useEffect(() => {
    isProcessingRef.current = isProcessing;
  }, [isProcessing]);

  useEffect(() => {
    let errorInput = document
      .getElementById("billing")
      .querySelector(".sq-card-message");

    let count = 0;
    let errArr = Object.keys(localState.sectionErrors);
    errArr.map((error) => {
      if (localState.sectionErrors[error].length === 0) {
        count++;
      }
      return error;
    });

    if (errorInput) {
      if (errorInput.innerHTML.length !== 0 || count !== errArr.length) {
        setErrMsg(inputErrText);
      }
      if (errorInput.innerHTML.length === 0 && count === errArr.length) {
        setErrMsg("");
      }
    }
  }, [localState]);

  const setTimer = (orderMsgInKickoff = "") => {
    timeOut.current = setTimeout(() => {
      if (isProcessingRef.current) {
        setIsProcessing(false);
        const attachmentPayload = {
          error: "Order timed out",
          orderMsgInKickoff: orderMsgInKickoff,
          orderConfiguration: globalState.cartConfig,
          customerInfo: localState,
          customerCart: globalState.cart,
          deviceDetails: globalState.deviceDetails,
        };

        dispatch(setPopupHTML(<OrderErrorMessage />));
        sendAlertEmail({
          message: createErrorHTML(localState, orderErrText, globalState.cart),
          attachmentContent: JSON.stringify(attachmentPayload),
        });
        return setErrMsg(orderErrText);
      }
    }, 30000);
  };

  const kickoffPaymentProcess = async () => {
    setErrMsg("");
    setIsProcessing(true);
    setOrderMessage(initialOrderMsg);
    // set a timer for 30 seconds. Once 30 seconds is up and the order has not been placed, stop the process and send an email
    // kill timer if no errors are found
    let orderMsgInKickoff = initialOrderMsg;
    setTimer(orderMsgInKickoff);

    // set fulfillment time and type
    let fulfillmentTime = "";
    let fulfillmentDate = "";
    let fulfillmentType = "";
    let pickupLocation = "";
    let deliveryCity = localState.deliveryCity
      ? localState.deliveryCity + ", "
      : "";
    let deliveryState = localState.deliveryState
      ? localState.deliveryState.name + ", "
      : "";
    let fulfillmentAddress = `${localState.deliveryAddress} ${localState.deliveryApt} ${deliveryCity}${deliveryState} ${localState.deliveryZipcode}`;

    if (globalState.cartConfig.orderType.id === "same") {
      fulfillmentTime = `${globalState.cartConfig.time}`;
      fulfillmentType = "Pickup Today";
      pickupLocation = globalState.cartConfig.location;
      fulfillmentDate = dayjs().format("YYYY-MM-DD");
    } else if (globalState.cartConfig.orderType.id === "prepick") {
      fulfillmentDate = dayjs(globalState.cartConfig.date).format("YYYY-MM-DD");
      fulfillmentTime = globalState.cartConfig.time;
      pickupLocation = globalState.cartConfig.location;
      fulfillmentType = `Pre-order ${pickupLocation}`;
    } else if (globalState.cartConfig.orderType.id === "delivery") {
      if (globalState.cartConfig.deliveryType.name.includes("Skyway")) {
        fulfillmentDate = dayjs(globalState.cartConfig.date).format(
          "YYYY-MM-DD"
        );
        fulfillmentTime = globalState.cartConfig.time;
        fulfillmentType = `Skyway Delivery`;
      } else {
        fulfillmentDate = `${dayjs(globalState.cartConfig.date).format(
          "YYYY-MM-DD"
        )}`;
        fulfillmentType = `Metro Delivery`;
      }
    }

    // MARK: createOrder payload
    const payload = {
      cart: globalState.cart,
      donutPicksKey: globalState.donutPickItems,
      delivery: globalState.cartConfig.deliveryType
        ? globalState.cartConfig.deliveryType
        : "",
      discount: globalState.orderDiscount ? globalState.orderDiscount : "",
      donutDiscount: globalState.orderTotals.donutDiscount
        ? globalState.orderTotals.donutDiscount
        : "",
      tip: globalState.orderTip.replace(".", ""),
      giftCard:
        globalState.giftCards.length > 0
          ? {
              sourceId: globalState.giftCards[0].id,
              amount: globalState.orderTotals.giftCard,
            }
          : "",
      fulfillmentType: fulfillmentType,
      fulfillmentTime: fulfillmentTime,
      fulfillmentDate: fulfillmentDate,
      pickupLocation: pickupLocation,
      fulfillmentAddress: fulfillmentAddress.trimEnd(),
      notes: globalState.orderMessage,
      instructions: globalState.orderInstructions,
      location:
        globalState.cartConfig.orderType.id === "same"
          ? globalState.cartConfig.location.includes("IDS")
            ? "IDS"
            : ""
          : "",
      customerInfo: {
        displayName: localState.firstName + " " + localState.lastName,
        emailAddress: localState.email,
        phoneNumber: localState.phone,
      },
    };

    let orderRes = await createOrder(payload);
    if (orderRes.data.errors) {
      const attachmentPayload = {
        error: orderRes.data.errors,
        orderConfiguration: globalState.cartConfig,
        customerInfo: localState,
        customerCart: globalState.cart,
        deviceDetails: globalState.deviceDetails,
      };

      sendAlertEmail({
        message: createErrorHTML(localState, orderErrText, globalState.cart),
        attachmentContent: JSON.stringify(attachmentPayload),
      });
      setIsProcessing(false);
      return setErrMsg(orderErrText);
    } else {
      orderMsgInKickoff = "Checking payment details...";
      setOrderMsg(orderMsgInKickoff);
      clearTimeout(timeOut.current);
      setTimer(orderMsgInKickoff);
    }

    const inputRes = await checkInputs();
    // returns info if there are inputs that need to be filled out
    if (inputRes.errors.length !== 0) {
      clearTimeout(timeOut.current);
      setIsProcessing(false);
      return setErrMsg(inputErrText);
    }

    // once order has been created pass id in
    let locationPayment = globalState.payments[0];

    if (globalState.cartConfig.orderType.id === "same") {
      if (globalState.cartConfig.location.includes("IDS"))
        locationPayment = globalState.payments[1];
    }

    // initiate payment
    const billingPayload = {
      ...inputRes.billing,
      billingPhone: inputRes.contact.phone,
      billingEmail: inputRes.contact.email,
    };
    const paymentRes = await handlePaymentMethodSubmission(
      locationPayment,
      globalState.orderTotals.total > 0 ? localState.ccToken : "",
      Number(orderRes.data.netAmountDueMoney.amount),
      Number(payload.tip),
      payload.giftCard,
      orderRes.data.id,
      billingPayload
    );

    if (paymentRes.errors) {
      const attachmentPayload = {
        error: paymentRes.errors,
        orderConfiguration: globalState.cartConfig,
        customerInfo: localState,
        customerCart: globalState.cart,
        deviceDetails: globalState.deviceDetails,
      };

      if (Array.isArray(paymentRes.errors)) {
        paymentRes.errors.map((error) => {
          if (error.code !== "GENERIC_DECLINE") {
            const errorReason = error.code.replace(/_/g, " ");
            paymentErrText = `An error occurred for this reason: ${errorReason}. Please check your payment method and try again.`;
          }
          return error;
        });
      }

      sendAlertEmail({
        message: createErrorHTML(localState, paymentErrText, globalState.cart),
        attachmentContent: JSON.stringify(attachmentPayload),
      });
      setIsProcessing(false);
      return setErrMsg(paymentErrText);
    }

    // add order to spreadsheet
    let payment_id = "";
    let order_date = "";
    let last_4_CC = "";
    let receipt_number = "";
    if (Number(orderRes.data.netAmountDueMoney.amount) === 0) {
      payment_id = paymentRes.id;
      order_date = dayjs(paymentRes.createdAt).format("YYYY-MM-DD");
      last_4_CC = "none";
      receipt_number = {
        text: paymentRes.id.substring(0, 4),
        link: `https://squareup.com/receipt/preview/${paymentRes.id}`,
      };
    } else {
      payment_id = paymentRes.order.tenders[0].paymentId;
      order_date = dayjs(paymentRes.order.createdAt).format("YYYY-MM-DD");
      last_4_CC = paymentRes.order.tenders[0].cardDetails.card.last4;
      receipt_number = {
        text: paymentRes.order.tenders[0].paymentId.substring(0, 4),
        link: `https://squareup.com/receipt/preview/${paymentRes.order.tenders[0].paymentId}`,
      };
    }

    let strLineitems = "";
    orderRes.data.lineItems.map((item, i) => {
      strLineitems += `${item.name} x${item.quantity}${
        i === orderRes.data.lineItems.length - 1 ? "" : `\n\n`
      }`;
      return item;
    });

    const slipPayload = {
      payment_id: payment_id,
      order_date: order_date,
      fulfillment_date: Number(
        JSDateToExcelDate(dayjs(fulfillmentDate).toDate())
      ),
      customer_name: localState.firstName + " " + localState.lastName,
      last_4_CC: Number(last_4_CC),
      tip_total: Number(payload.tip / 100),
      order_total: Number(orderRes.data.netAmountDueMoney.amount / 100),
      receipt_number: receipt_number,
      order_type: fulfillmentType,
      order_time: timeDateToExcel(fulfillmentDate, fulfillmentTime),
      pickup_location: pickupLocation,
      delivery_address: localState.deliveryAddress,
      delivery_city: localState.deliveryCity,
      delivery_zip: localState.deliveryZipcode
        ? Number(localState.deliveryZipcode)
        : "",
      phone_number: localState.phone,
      email_address: localState.email,
      instructions: globalState.orderInstructions
        ? globalState.orderInstructions
        : " ",
      note: globalState.orderMessage ? globalState.orderMessage : " ",
      order_details: strLineitems,
    };
    orderMsgInKickoff = "Sending order to the store...";
    setOrderMsg(orderMsgInKickoff);
    clearTimeout(timeOut.current);

    // Large order check
    if (slipPayload.order_total >= 350) {
      const largeOrderDetails = {
        fulfillment_date: fulfillmentDate,
        fulfillment_type: fulfillmentType,
        fulfillment_time: fulfillmentTime,
      };
      const contactInfo = globalState.siteData.contact_info;
      const weddingEventsEmail = contactInfo[0].find(
        (contact) => contact[0].content === "Wedding Events"
      )[2].content;
      const bakeryEmail = contactInfo[0].find(
        (contact) => contact[0].content === "Bakery"
      )[2].content;

      sendAlertEmail({
        message: largeOrderHTML(
          localState,
          globalState.cart,
          largeOrderDetails
        ),
        subject: "Large Online Order Alert",
        to: [{ email: weddingEventsEmail }, { email: bakeryEmail }],
      });
    }
    // if addOrderRow fails, server will send email
    addOrderRow({ order: slipPayload });

    let payment = "";
    let goToConfirm = false;
    if (Number(orderRes.data.netAmountDueMoney.amount) === 0) {
      payment = paymentRes;
      goToConfirm = true;
    }
    if (paymentRes.order) {
      payment = paymentRes.order.tenders;
      goToConfirm = true;
    }

    if (goToConfirm) {
      let confirmationPayload = {
        payment: payment,
        contact: inputRes,
        order: orderRes.data,
        tip: payload.tip,
        message: globalState.orderMessage,
        instructions: globalState.orderInstructions,
        cart: globalState.cart,
        cartConfig: globalState.cartConfig,
      };
      orderMsgInKickoff = "Sending confirmation emails...";
      setOrderMsg(orderMsgInKickoff);
      // if sendOrderConfirmationEmails fails, server will send error
      sendOrderConfirmationEmails(globalState, {
        ...confirmationPayload,
        fulfillmentDate: fulfillmentDate,
      });
      await dispatch(setConfirmation(confirmationPayload));
      await dispatch(setGiftCard([]));
      await dispatch(loadCartFromStorage([]));
      await dispatch(setCartConfig(""));
      await dispatch(setOrderMessage(""));
      await dispatch(setOrderDiscount(""));
      await dispatch(setOrderInstructions(""));
      setIsProcessing(false);
      clearTimeout(timeOut.current);
      navigate(ROUTES.CONFIRMATION);
    }
  };

  const checkInputs = async () => {
    let billingPayload = {};
    let deliveryPayload = {};
    let contactPayload = {};
    let errorInputPayload = [];

    let errorInput = document
      .getElementById("billing")
      .querySelector(".sq-card-message");
    if (errorInput) {
      if (errorInput.innerHTML.length !== 0) {
        return setErrMsg(inputErrText);
      }
    }

    Object.keys(localState.sectionErrors).map((sectionError) => {
      if (localState.sectionErrors[sectionError].length > 0) {
        errorInputPayload.push(...localState.sectionErrors[sectionError]);
      }
      return sectionError;
    });

    let checkoutInputs = [...document.getElementsByTagName("input")];

    let localStateKeys = Object.keys(localState);
    for (let i = 0; i < localStateKeys.length; i++) {
      let input = localStateKeys[i];
      let value = input.includes("State")
        ? localState[input].name
        : localState[input];
      checkoutInputs.map((checkOutInput) => {
        if (
          input === checkOutInput.id &&
          checkOutInput.hasAttribute("required")
        ) {
          if (localState[input] === "") {
            let inputToThrow = document.getElementById(checkOutInput.id);

            if (input.includes("delivery")) {
              if (globalState.cartConfig.deliveryType) {
                errorInputPayload.push(input);
                inputToThrow.value = "";
                inputToThrow.focus();
                inputToThrow.blur();
              }
            } else {
              errorInputPayload.push(input);
              inputToThrow.value = "";
              inputToThrow.focus();
              inputToThrow.blur();
            }
          }
        }
        return checkOutInput;
      });
      if (input.includes("billing")) {
        billingPayload = { ...billingPayload, [input]: value };
      } else if (input.includes("delivery")) {
        deliveryPayload = { ...deliveryPayload, [input]: value };
      } else if (input !== "ccToken" && input !== "sectionErrors") {
        contactPayload = { ...contactPayload, [input]: value };
      }
    }

    const payload = {
      billing: billingPayload,
      delivery: deliveryPayload,
      contact: contactPayload,
      errors: errorInputPayload,
    };

    return payload;
  };

  const errorMsg = (
    <Grid2 xs={12}>
      <p
        className={`${lap ? "m-t-5" : "m-b-10"} max-width-300 m-r-10 color-red`}
      >
        {errMsg}
      </p>
    </Grid2>
  );
  const orderSubmitting = (
    <Grid2 xs={12}>
      <p className={`${lap ? "m-t-5" : "m-b-10"} max-width-300 m-r-10`}>
        {orderMsg}
      </p>
    </Grid2>
  );

  return (
    <Grid2
      container
      className='width-min'
      alignContent='center'
      justifyContent={lap ? "space-between" : "center"}
    >
      {!lap && errorMsg}
      {!lap && isProcessing && orderSubmitting}
      <Grid2
        xs={12}
        container
        justifyContent={"flex-end"}
      >
        <button
          onClick={() => kickoffPaymentProcess()}
          className={`red-button min-width-200 max-width-200 height-52 ${
            isProcessing ? "pointer-none" : ""
          }`}
        >
          <span className='font-size-16 center'>
            <span className='m-r-7'>
              {isProcessing ? "Placing Order" : "Place Order"}
            </span>
            {isProcessing ? (
              <div className='height-40'>
                <LoadingState />
              </div>
            ) : (
              <span className='m-l-7'>{toUSD(total)}</span>
            )}
          </span>
        </button>
        {!lap && location.pathname !== ROUTES.CONFIRMATION && (
          <button
            className='red-button-flat m-t-20'
            onClick={() => dispatch(setPopupHTML(<ClearCart />))}
          >
            Clear cart
          </button>
        )}
      </Grid2>
      {lap && errorMsg}
      {lap && isProcessing && orderSubmitting}
    </Grid2>
  );
};

export default withLocalContext(SubmitBtn);
